<template>
  <div class="billing-history-details">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :loading="isLoading"
      class="billing-history-details__table standart-text"
      @sort-change="onSortChange"
    >
      <base-table-row v-for="item in paginList" :key="item.id" :item="item" :columns="tableHead">
        <template #date>
          {{ $d(item.date, 'short') }}
        </template>
        <template #title>
          {{ item.title }}
        </template>
        <template #sum>
          {{ (item.type == 'expense' ? '- ' : '+ ') + $n(item.sum, 'currency') }}
        </template>
        <template #id>
          {{ item.id }}
        </template>
        <template #number>
          {{ item.number }}
        </template>
      </base-table-row>
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="big"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import NewPagination from '@/components/Pagination/NewPagination.vue';
import isDate from 'date-fns/isDate';
import pagination from '@/mixins/pagination';
export default {
  name: 'BillingHistoryDetails',
  components: { BaseTable, BaseTableRow, NewPagination },
  mixins: [pagination],
  props: {
    dateFilter: {
      type: Object,
      default: null,
      validator: obj => {
        return !obj || (obj.start && isDate(obj.start) && obj.end && isDate(obj.end));
      },
    },
  },
  data() {
    return {
      stepOptions: [25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 25,
      },
      tableHead: [
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          sort: {
            prop: 'date',
            order: 'asc',
          },
          style: {
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'title',
          label: this.$t('tableHead.title'),
          sort: {
            prop: 'title',
            order: 'asc',
          },
          style: {
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'sum',
          label: this.$t('tableHead.sum'),
          sort: {
            prop: 'sum',
            order: 'asc',
          },
          style: {
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'number',
          label: this.$t('tableHead.number'),
          style: {
            verticalAlign: 'baseline',
          },
        },
      ],
    };
  },
  computed: {
    sortedList() {
      return this.$store.getters['moduleBilling/moduleHistory/GET_SORTED_LIST'].filter(
        x => x.sum !== 0
      );
    },
    list() {
      if (!this.dateFilter) return this.sortedList;
      const { start, end } = this.dateFilter;
      return this.sortedList.filter(i => i.date >= start && i.date <= end);
    },
    isLoading() {
      return this.$store.state.moduleBilling.moduleHistory.isLoading;
    },
    sort() {
      return this.$store.state.moduleBilling.moduleHistory.sort;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
    showPagination() {
      return this.list.length > this.stepOptions[0];
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleBilling/moduleHistory/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "number": "Платеж",
      "date": "Дата",
      "title": "Описание операции",
      "sum": "Сумма",
      "id": "Транзакция"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
.billing-history-details {
  &__pagination {
    margin-top: 1.5rem;
  }
  >>> .base-table-row__col--sum {
    white-space: nowrap;
  }
}
</style>
